import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Brand from './Brand'
import Container from 'react-bootstrap/Container'
import NavLink from 'react-bootstrap/esm/NavLink'
import Countries from './Countries'
import {Link} from 'react-router-dom'


const NavBar = () => {
  return (
    <div className='grey'>
      
      <Container fluid className='navbarMenu'>
        <Link to={'/'}>
          <Brand/>
        </Link>
        <Navbar variant="dark" expand="md">

        <Navbar.Toggle className='toggle' aria-controls="navbarScroll"/>
      
        <Navbar.Collapse className='menu'>

          <NavLink className='navItems' href="https://www.youtube.com/channel/UCxWedPaJvnyZXCC7f65cdzg" target="_blank" rel='noreferrer'>
            ON DEMAND
          </NavLink>

          <NavLink className='navItems' href='https://www.solutionboxusa.com/selectorcatalogo' target='_blank' rel='noreferrer'>
            DIGITAL CATALOG
          </NavLink>
          
          <NavDropdown title='HEADQUARTERS' className='navItems'>
            <Countries/>
          </NavDropdown>

          <Link to={'/about-us'} className='navItems'>ABOUT US</Link>

          <Button className='btnNav navItems' href="mailto:info@solutionbox.com.hk">CONTACT US</Button>

        </Navbar.Collapse>

        </Navbar>
        </Container>
      
    </div>
  )
}

export default NavBar